// Palette
$white: #fff;
$black: #0d0d0d;
$green: #2aaa2f;
$yellow: #ffc107;
$red: #dc3545;
$orange: #ff6100;

// Theme
$primary: #cf1220;
$secondary: #1f1f1f;
$success: $green;
$info: #787a79;
$warning: $yellow;
$danger: $orange;
$light: #1f1f1f;
$dark: #181818;
$highlight: #2b2b2b;

// Body
$body-bg: $dark;
$body-color: $info;
$border-color: $info;
$hr-border-color: $light;
$text-muted: $info;

// Fonts
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;

// Breadcrumbs
$breadcrumb-bg: $dark;
$breadcrumb-divider-color: $info;
$breadcrumb-active-color: $info;
$breadcrumb-divider: quote("/");

// Tables
$table-accent-bg: rgba($white, .05);
$table-hover-bg: rgba($white, .075);
$table-border-color: $info;

// Input
$input-bg: $dark;
$input-disabled-bg: $black;
$input-color: $info;
$input-border-color: $info;
$input-border-width: 0;
$input-btn-border-width: 0;
$input-btn-focus-color: rgba($info, .25);
$input-focus-border-color: lighten($info, 25%);
$input-placeholder-color: lighten($highlight, 10%);

// Input group
$input-group-addon-color: $input-color;
$input-group-addon-bg: $light;
$input-group-addon-border-color: $light;

// List group
$list-group-bg: $light;
$list-group-border-color: $light;
$list-group-hover-bg: $light;

// Cards
$card-bg: $light;
$card-border-color: $light;
$card-cap-bg: $highlight;
$thumbnail-border-color: $light;

// Jumbotron
$jumbotron-bg: $light;

// Navbar
$navbar-brand-padding-y: 0;
$navbar-brand-font-size: 1rem;

// Links
$link-hover-color: $primary;

// Modal
$modal-lg: 992px;

@import 'core';

.bg-black {
  background-color: $black;
}
