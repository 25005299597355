.order-item {
  margin: 1rem 0 0 0;

  &:last-child {
    border-bottom: 0;
  }

  .order-item-price {
    color: $info;
  }
}

.order-item-title {
  width: 75%;

  img {
    width: 80px;
  }

  h1 {
    font-size: $font-size-base * 1.25;
    margin-left: 1rem;
    display: inline;
  }
}

.card-action {
  color: $info;
  text-decoration: none;

  &:hover {
    color: $primary;
    border-color: $primary;
    text-decoration: none;
  }

  .icon {
    font-size: $font-size-base * 5;
  }
}

.card-action-link {
  &:hover {
    text-decoration: none;
  }
}
