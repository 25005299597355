.product-showcase {
  background-color: #fff;
}

.product-details {
  background-color: $body-bg;

  .img-thumbnail {
    width: 128px;
  }
}

.search-result {
  color: $info;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
