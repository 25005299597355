.ion-md:before {
  position: relative;
  font-size: $font-size-base * 1.45;
  line-height: 0.75em;
  vertical-align: -15%;
}

.ion-lg:before {
  position: relative;
  font-size: $font-size-base * 1.75;
  line-height: 0.75em;
  vertical-align: -15%;
}

.ion-2x:before {
  position: relative;
  font-size: $font-size-base * 2;
}

.ion-3x:before {
  position: relative;
  font-size: $font-size-base * 3;
}

.ion-4x:before {
  position: relative;
  font-size: $font-size-base * 4;
}

.ion-5x:before {
  position: relative;
  font-size: $font-size-base * 5;
}

.quantity-select {
  width: 60px;
}

.wait-autocomplete, .billing-wait-autocomplete, .billing-section {
  display: none;
}
