.chat {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;
    padding: 12px;

    .chat-body {
      p {
        margin: 0;
      }
    }

    &.left {
      .chat-body {
        margin-left: 60px;
      }
    }

    &.right {
      background-color: lighten($light, 5%);
      border-radius: 5px;

      .chat-body {
        margin-right: 60px;
      }
    }
  }
}
