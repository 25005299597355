main {
  margin-top: 6rem;
}

#homeCarousel, #blogCarousel, #promotionsCarousel {
  margin-top: -1.5rem;
}

.navbar-nav {
  font-size: ($font-size-base * 1.275);
}

.bg-black {
  background-color: $black;
}

.navbar-button {
  margin: 0 0 0 1rem;
  color: $info;
  font-size: $font-size-lg;

  &:hover {
    color: rgba($white, .75);
    text-decoration: none;
  }
}

.list-group-item-action {
  .icon {
    margin-right: .40rem;
  }
}

.footer {
  width: 100%;
  margin-top: 8rem;
  margin-bottom: 2rem;
}

.breadcrumb-item a {
  color: $info;
  font-weight: bold;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  width: auto;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: $body-color;
  text-align: left;
  list-style: none;
  background-color: $black;
  background-clip: padding-box;
  border: 0;
  border-radius: 12px;
}

.navbar-icon-menu {
  text-align: center;

  .nav-item {
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0 1.9rem;

    img {
      margin-bottom: 0.8rem;
      width: 90px;
    }
  }

  @include media-breakpoint-down(md) {
    text-align: left;

    .nav-item {
      margin: 0;

      img {
        display: none
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #search-input {
    font-size: 1.5rem;
  }

  .navbar-button {
    display: block;
    margin: 1.1rem 0;
  }
}
