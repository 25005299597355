.social-icons {
  margin-top: 1.5rem;

  a {
    color: $info;
    padding: .5rem;

    &:hover {
      color: $primary;
    }
  }
}
