.cart-item {
  margin: 2rem;
  border-bottom: 1px solid $hr-border-color;

  &:last-child {
    border-bottom: 0;
  }

  .cart-item-price {
    color: $primary;
  }
}

.cart-title {
  width: 75%;

  img {
    width: 80px;
  }

  h1 {
    font-size: $font-size-base * 1.25;
    margin-left: 1rem;
    display: inline;
  }
}
