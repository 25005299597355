.spinner {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 3000;
  position: fixed;
  color: $primary;

  &:after {
    content: "⚆";
    font-size: 6rem;
    top: 50%;
    left: 50%;
    margin-left: -2.5rem;
    margin-top: -2.5rem;
    padding: 0;
    position: absolute;
    text-rendering: optimizeSpeed;
    animation-name: spin;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.disabled {
  display: none;
}
