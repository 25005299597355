@import '~bootstrap/scss/bootstrap';
@import '~ionicons/dist/scss/ionicons';
@import '~instantsearch.js/dist/instantsearch.css';
@import 'account';
@import 'carousel';
@import 'cart';
@import 'chat';
@import 'input';
@import 'navbar';
@import 'portfolio';
@import 'product';
@import 'social';
@import 'spinner';
@import 'video';

// Generic
.img-modal {
  cursor: pointer;
}

.no-decoration {
  &:hover {
    text-decoration: none;
  }
}

#partner-map {
  height: 500px;
  width: 100%;
}
